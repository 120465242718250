<template>
  <div class="bg-warning rounded p-3 text-center">
    <h5>{{ $t('general.sendcampaignto') }}:</h5>
    <div class="px-2 text-start" style="max-height:300px;overflow-y:auto;">

      <div v-for="{ name, phoneNumbers, checkboxTicked } in SMSTargetLists" :key="name">
        <label class="d-flex bg-white rounded-1 py-1 px-2 cursor-pointer w-100 mb-2"
          :class="{ 'bg-primary-subtle': checkboxTicked }">
          <span class="me-auto text-truncate pe-3">{{ name }}</span>
          <span class="badge text-bg-light py-0 me-2 d-flex align-items-center"
            style="background:#eee !important;padding-top:3px !important;">{{ phoneNumbers.length ?? 0 }}</span>
          <input class="form-check-input" type="checkbox" @change="onCheckboxChange(name, checkboxTicked)"
            style="min-width:18px;min-height:18px;" />
        </label>
      </div>
    </div>

    <Button @click="promptSendCampaign()" class="btn btn-primary w-100 d-block mt-2 text-dark"
      :disabled="isLoading || this.selectedTargetListsLength == 0">
      {{ $t('general.send_sms') }}
    </Button>
    <Button @click="promptDeleteLists()" class="btn btn-danger w-100 d-block mt-2 text-dark"
      :disabled="isLoading || this.selectedTargetListsLength == 0">
      {{ $t('general.delete_selected') }}
    </Button>
    <hr />
    <Button class="btn btn-warning border border-dark w-100 d-block mt-2" :disabled="isLoading" data-bs-toggle="modal"
      data-bs-target="#uploadSMSList">
      <i class="fa-solid fa-upload"></i> {{ $t('general.upload_csv_list') }}
    </Button>
  </div>

  <div class="modal fade" id="sendSMSCampaign" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5 fw-bold m-0 text-center">{{ $t('general.are_you_sure') }}</h1>
        </div>
        <div class="modal-body">
          <p>
            Du kommer nu att skicka <b>{{ this.campaign ? this.campaign.title : '' }}</b> till
            <b>{{ this.sendToAmount }}</b> kunder via SMS.
          </p>

          <small><b>Notera:</b>Om du redan har skickat detta erbjudande till en kund kommer de inte få det igen.</small>
        </div>
        <div class="modal-footer">
          <Button @click="sendCampaign()" type="button" class="btn btn-success me-2" :loading="isLoading">
            {{ $t('general.yes_send') }}
          </Button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" data-bs-target="#sendSMSCampaign"
            :disabled="isLoading">
            {{ $t('general.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="deleteSMSLists" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5 fw-bold m-0 text-center">{{ $t('general.are_you_sure') }}</h1>
        </div>
        <div class="modal-body">
          <p>{{ $t('general.delete_following_lists') }}</p>
          <ul class="list-group mb-3">
            <li class="list-group-item" v-for="filteredListName in this.filteredListNames" :key="filteredListName">{{
              filteredListName
            }}</li>
          </ul>
          <p class="mb-0">{{ $t('general.action_irreversible') }}</p>
        </div>
        <div class="modal-footer">
          <Button @click="deleteLists()" type="button" class="btn btn-danger me-2" :loading="isLoading">
            {{ $t('general.yes_delete') }}
          </Button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" data-bs-target="#deleteSMSLists"
            :disabled="isLoading">
            {{ $t('general.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <SMSListUploadModal :elId="`uploadSMSList`" />
</template>

<script>
import {
  auth,
  getSMSTargetLists,
  sendCampaignSMS,
  deleteSMSTargetLists
} from '@/firebase'

import Button from '../misc/Button.vue'
import SMSListUploadModal from './SMSListUploadModal.vue';
import { Modal } from 'bootstrap'
import { toast } from 'vue3-toastify';

export default {
  components: {
    Button,
    SMSListUploadModal
  },
  props: {
    campaignId: String,
    campaign: Object,
  },
  data() {
    return {
      isLoading: true,
      userUid: '',
      loadingSMSlists: false,
      SMSTargetLists: this.emptySMSTargetLists(),
      sendToAmount: 0,
      selectedTargetLists: [],
      selectedTargetListsLength: 0,
      tooManyNumbers: false,
      uniquePhoneNumbers: []
    }
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          this.userUid = user.uid
          this.prepSendCampaign()
          this.isLoading = false
        }
      } catch (error) {
        console.log('Could not find a store')
      }
    })
  },
  methods: {
    emptySMSTargetLists() {
      return [{ name: '-', phoneNumbers: [], checkboxTicked: false }];
    },
    async prepSendCampaign() {
      this.isLoading = true;
      this.loadingSMSlists = true;

      this.selectedTargetListsLength = 0;
      this.SMSTargetLists = this.emptySMSTargetLists();

      const smsTargetLists = await getSMSTargetLists(this.userUid)
      if (!smsTargetLists.error) {
        this.SMSTargetLists = smsTargetLists.map(list => {
          list.checkboxTicked = false;
          return list;
        });
      } else {
        toast.error(smsTargetLists.message);
      }

      this.sendingCampaignSMS = true

      this.isLoading = false;
      this.loadingSMSlists = false;
    },
    async promptSendCampaign() {
      this.isLoading = true;

      // filter the array for the lists where the checkbox is ticked
      this.selectedTargetLists = this.SMSTargetLists.filter(list => list.checkboxTicked);

      // extract the phone numbers of the filtered lists
      const phoneNumbers = this.selectedTargetLists.map(list => list.phoneNumbers);

      // remove duplicate phone numbers
      this.uniquePhoneNumbers = phoneNumbers.reduce((acc, current) => {
        return acc.concat(current.filter(phoneNumber => acc.indexOf(phoneNumber) === -1));
      }, []);

      this.tooManyNumbers = this.uniquePhoneNumbers.length > 2000

      let sendSMSCampaign_modal = Modal.getInstance(document.getElementById('sendSMSCampaign'))

      if (sendSMSCampaign_modal) {
        sendSMSCampaign_modal.show();
      } else {
        new Modal(document.getElementById('sendSMSCampaign')).show()
      }

      this.sendToAmount = this.uniquePhoneNumbers.length;

      this.isLoading = false;
    },
    async sendCampaign() {
      this.isLoading = true;

      const response = await sendCampaignSMS({
        campaignId: this.campaignId,
        phoneNumbers: Object.values(this.uniquePhoneNumbers)
      });

      if (response.data && response.data.error) {
        toast.error(response.data.message);
      } else if (response.data && !response.data.error) {
        toast.success(response.data.message);
        Modal.getInstance(document.getElementById("sendSMSCampaign")).hide();
      }

      this.isLoading = false;
    },
    async promptDeleteLists() {
      this.isLoading = true;

      this.selectedTargetLists = this.SMSTargetLists.filter(list => list.checkboxTicked);
      this.filteredListNames = this.selectedTargetLists.map(list => list.name).filter(name => name !== 'Samtliga kunder');

      if(this.filteredListNames.length == 0) {
        toast.error('No valid lists were selected, refresh the page and try again.');
        this.isLoading = false;
        return false;
      }

      let deleteSMSLists_modal = Modal.getInstance(document.getElementById('deleteSMSLists'))
      if (deleteSMSLists_modal) {
        deleteSMSLists_modal.show();
      } else {
        new Modal(document.getElementById('deleteSMSLists')).show()
      }

      this.isLoading = false;
    },
    async deleteLists() {
      this.isLoading = true;

      const response = await deleteSMSTargetLists(this.filteredListNames)

      if (response.data && response.data.error) {
        toast.error(response.data.message);
      } else if (response.data && !response.data.error) {
        toast.success(response.data.message);
      }

      await this.prepSendCampaign()

      Modal.getInstance(document.getElementById("deleteSMSLists")).hide();
      this.isLoading = false;
    },
    onCheckboxChange(name, checkboxTicked) {
      this.selectedTargetListsLength = 0
      this.SMSTargetLists.forEach(list => {
        if (list.name === name) {
          list.checkboxTicked = !checkboxTicked;
        }

        if (list.checkboxTicked) this.selectedTargetListsLength++;
      });
    },
  },
}
</script>