<template>
  <div>
    <div class="modal fade" :id="this.elId" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
      data-bs-keyboard="false">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <h1 class="modal-title fs-5 fw-bold mb-2">{{ $t('general.create_sms_list') }}</h1>

            <div class="alert alert-warning d-flex align-items-center" role="alert">
              <i class="fa fa-warning align-middle me-3"></i>
              <div class="align-middle">
                {{ $t('general.phone_comma_seperated') }}
              </div>
            </div>
            <!-- <div class="position-relative border border-2 rounded-4 overflow-hidden mb-3 text-center"
              :class="{ 'border-dashed': !this.showSpinner }">
              <div ref="dropzone" class="dropzone position-relative cursor-pointer py-5" @dragover.prevent
                @dragleave="handleDragLeave" @drop="handleDrop" @click="handleClick">
                <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" />
                <div v-if="this.file">
                  <p class="m-0"><b>{{ $t('general.file_selected') }}:</b> {{ this.file.name }}</p>
                  <p class="my-1 text-muted">{{ $t('general.to_replace_file') }}</p>
                </div>
                <p class="m-0" v-else>{{ $t('general.drop_csv_browse') }}</p>
              </div>
              <div
                class="position-absolute w-100 h-100 top-0 start-0 bg-light d-flex justify-content-center align-items-center"
                v-if="this.showSpinner">
                <div>
                  <div class="spinner-border spinner-border-sm text-primary" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                  </div>
                  <div class="d-inline-block ms-2">
                    {{ $t('general.processing') }}...
                  </div>
                  <br />
                  <small class="text-muted">{{ $t('general.dont_leave_processing') }}</small>
                </div>
              </div>
            </div> -->
            <div class="row">
              <div class="mb-2">
                <textarea class="form-control" :placeholder="$t('general.smslist')" rows="3"
                  v-model="this.description"
                  :class="{ 'is-invalid': !isRequired.description }"></textarea>
                <label style="font-size: 0.8rem" class="invalid-feedback" v-if="!isRequired.description">
                  {{ $t('general.smslist_required') }}
                </label>
              </div>
            </div>
            <div class="row" v-if="this.validationTriggered">
              <div class="col-md-6">
                <div>
                  <p class="my-2">Preview of valid phone numbers:</p>
                  <div class="position-relative bg-success-subtle py-2 px-3 rounded-3">
                    <div class="my-2" v-for="phoneNumber in validPhoneNumbers.slice(0, 6)" :key="phoneNumber">{{
                      phoneNumber
                    }}
                    </div>
                    <div class="content-fade-bottom" v-if="validPhoneNumbers.length > 4"></div>
                  </div>
                  <p class="mt-1">Found <b class="text-success">{{ validPhoneNumbers.length }}</b> phone
                    numbers.</p>
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <p class="my-2">Preview of invalid values:</p>
                  <div class="position-relative bg-light py-2 px-3 rounded-3">
                    <div class="my-2" v-for="phoneNumber in invalidPhoneNumbers.slice(0, 6)" :key="phoneNumber">{{
                      phoneNumber
                    }}
                    </div>
                    <div class="content-fade-bottom" v-if="invalidPhoneNumbers.length > 4"></div>
                  </div>
                  <p class="mt-1">
                    Found <span class="text-danger fw-bold">{{ invalidPhoneNumbers.length }}</span> invalid values.
                  </p>
                </div>
              </div>
            </div>
            <div class="row" v-if="this.validationTriggered">
              <div class="col-sm-6">
                <button class="btn btn-primary w-100 mb-2"
                  :disabled="validPhoneNumbers.length === 0"
                  data-bs-toggle="modal" data-bs-target="#createSMSList">
                  {{ $t('general.create_list') }}
                </button>
              </div>
              <div class="col-sm-6">
                <button class="btn btn-outline-warning w-100 mb-2" @click="downloadInvalidNumbers"
                  :disabled="invalidPhoneNumbers.length === 0">
                  Download invalid numbers
                </button>
              </div>
            </div>

            <!-- <div v-if="this.validPhoneNumbers.length > 2000" class="alert alert-danger d-flex align-items-center"
              role="alert">
              <i class="fa fa-circle-exclamation align-middle me-3"></i>
              <div class="align-middle">
                A list may have a maximum of 2,000 phone numbers, contact support to request more.
              </div>
            </div> -->
            <div class="mt-2">
              <button type="button" class="btn btn-primary w-100" @click="handleManualValidate">
                {{ $t('general.validate') }}
              </button>
            </div>
            <div class="mt-2">
              <button type="button" class="btn btn-secondary w-100" data-bs-dismiss="modal">
                {{ $t('general.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="createSMSList" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <h1 class="modal-title fs-5 fw-bold mb-2">Enter a name for your new list</h1>
            <input type="text" class="form-control mb-2" v-model="listName" placeholder="List name"
              @keyup.enter="createList" />
            <p>Your list will contain <b>{{ this.validPhoneNumbers.length }}</b> phone numbers.</p>
            <div class="text-center mt-2">
              <Button @click="createList" type="button" :loading="this.isLoading" class="btn btn-success me-2">
                {{ $t('general.create') }}
              </Button>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal" :disabled="this.isLoading">
                {{ $t('general.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createSMSTargetList
} from '@/firebase'

import Papa from "papaparse";
import { Modal } from 'bootstrap'
import { toast } from 'vue3-toastify';
import Button from '../misc/Button.vue'

export default {
  name: 'SMS List Upload',
  components: {
    Button
  },
  props: {
    elId: { type: String, default: 'uploadSMSList' }
  },
  data() {
    return {
      file: null,
      validPhoneNumbers: [],
      invalidPhoneNumbers: [],
      showSpinner: false,
      validationTriggered: false,
      listName: '',
      isLoading: false,
      description: '',
      isRequired: {
        description: true
      }
    };
  },
  methods: {
    handleClick() {
      this.$refs.fileInput.click();
    },
    handleDragLeave() {
      this.$refs.dropzone.classList.remove("active");
    },
    handleDrop(e) {
      e.preventDefault();
      this.$refs.dropzone.classList.remove("active");
      this.handleFileChange(e.dataTransfer);
    },
    handleFileChange(e) {
      let file = e.files ? e.files[0] : e.target.files[0];
      this.file = file;

      let fileSize = file.size / 1024 / 1024; // File size in MB
      let fileType = file.type;

      if (fileType !== 'text/csv') {
        alert('Invalid file type. Please upload a CSV file.');
        return;
      }
      if (fileSize > 25) {
        alert('File size is too large. Please upload a file smaller than 5 MB.');
        return;
      }

      this.showSpinner = true;
      Papa.parse(file, {
        complete: results => {
          let allPhoneNumbers = [...new Set(results.data.map(row => row[0]))];
          this.validPhoneNumbers = [...new Set(allPhoneNumbers.filter(phoneNumber => /^\+?(00)?[0-9]{9,15}$/.test(phoneNumber))
            .map(phoneNumber => phoneNumber.replace(/^00|^\+/, ''))
            .map(phoneNumber => phoneNumber.replace(/^0/, '46')))]
          this.invalidPhoneNumbers = [...new Set(allPhoneNumbers.filter(phoneNumber => !/^\+?(00)?[0-9]{9,15}$/.test(phoneNumber)))];
          this.showSpinner = false;
        }
      });
    },
    handleManualValidate(){
      if(!this.description) return
      const results = this.description.split(',');
      let allPhoneNumbers = [...new Set(results)];
      this.validPhoneNumbers = [...new Set(allPhoneNumbers.filter(phoneNumber => /^\+?(00)?[0-9]{9,15}$/.test(phoneNumber))
        .map(phoneNumber => phoneNumber.replace(/^00|^\+/, ''))
        .map(phoneNumber => phoneNumber.replace(/^0/, '46')))]
      this.invalidPhoneNumbers = [...new Set(allPhoneNumbers.filter(phoneNumber => !/^\+?(00)?[0-9]{9,15}$/.test(phoneNumber)))];
      this.validationTriggered = true;
    },
    downloadInvalidNumbers() {
      const invalidNumbersText = this.invalidPhoneNumbers.join('\n');
      const file = new Blob([invalidNumbersText], { type: 'text/plain' });
      const fileUrl = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = 'invalid_numbers.txt';
      link.click();
    },
    async createList() {
      //if (this.validPhoneNumbers.length > 2000) return toast.error('A list may have a maximum of 2,000 phone numbers, contact support to request more.')

      this.isLoading = true;

      if (this.listName.length < 3) {
        toast.error("List name is too short, enter at least 3 characters.");
        return this.isLoading = false;
      }

      if (this.validPhoneNumbers.length === 0) {
        toast.error("Phone numbers are required to create a list.");
        return this.isLoading = false;
      }

      const response = await createSMSTargetList({
        name: this.listName,
        phoneNumbers: Object.values(this.validPhoneNumbers)
      });

      if (response.data && response.data.error) {
        toast.error(response.data.message);
      } else if (response.data && !response.data.error) {
        toast.success(response.data.message);
        this.$parent.prepSendCampaign()
        Modal.getInstance(document.getElementById("createSMSList")).hide();
      }

      this.isLoading = false;
    }

  },
  mounted() {
    // this.$refs.dropzone.addEventListener("dragover", e => {
    //   e.preventDefault();
    //   this.$refs.dropzone.classList.add("active");
    // });
  }
};
</script>

<style>
.dropzone {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
}

.dropzone.active {
  background-color: #e0e0e0;
}
</style>
