<template>
  <div>
    <NavBar />
    <CampaignList />
  </div>
</template>
  
<script>
import CampaignList from '@/components/CampaignList.vue'
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'Home',
  components: { CampaignList, NavBar },
}
</script>
  